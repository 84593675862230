<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong class="mr-4">Users Management</strong>
            <a-button-group>
              <a-button
              class="btn btn-outline-primary"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            >
            <a-button
              class="btn btn-outline-success"
              @click="getData(true)"
              >Refresh Data</a-button
            >
            </a-button-group>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
          style="
            margin-left: -26px;
            margin-right: -26px;
            margin-top: -21px;
          "
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 300 }"
          size="small"
          :pagination="{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span>
                {{ text }}
              </span>
            </template>
          </template>
          <span slot="action" slot-scope="text, record, index">
            <a-icon
              type="edit"
              class="text-warning"
              @click="sendToForm('Update', record, record.id)"
              />
            <a-divider type="vertical"
              v-if="record.allow_Delete === 1"/>
            <a-icon
              v-if="record.allow_Delete === 1"
              type="delete"
              class="text-danger"
              @click="showDeleteConfirm(record)"
              />
            <a-divider type="vertical"/>
            <a-popover title="Change Password" v-model="record.changePassPop" trigger="click">
              <template slot="content">
                <label for="password"></label>
                <label for="password">New Password</label>
                <br>
                <a-input-password placeholder="Enter New Password" v-model="popinput.password" style="width: 200px"/>
                <!-- <a-input type="text" v-model="popinput.password" style="width: 200px"/> -->
                <br>
                <label for="repassword">Re-Type Password</label>
                <br>
                <a-input-password placeholder="Enter Re-Type Password" v-model="popinput.re_password" style="width: 200px"/>
                <!-- <a-input type="text" v-model="popinput.re_password" style="width: 200px"/> -->
                <br>
                <strong class="text-danger mt-2" style="cursor: pointer;" @click="hide(index)">Cancel</strong>
                <strong class="text-primary mt-2 ml-2" style="cursor: pointer;" @click="changePassword(record.id)">Submit</strong>
              </template>
              <a-icon type="lock" class="text-warning" style="cursor: pointer;"/>
            </a-popover>
          </span>
          <template slot="appList" slot-scope="text, record">
            <a-tag v-for="(data, index) in record.appLists" :key="index">{{ data.appName }}</a-tag>
          </template>
          <template slot="id_Default_App" slot-scope="text, record">
            <a-tag color="blue">{{ findName(record) }}</a-tag>
          </template>
          <template slot="user_type" slot-scope="text, record">
            <a-tag :color="record.user_type === 'User' ? 'blue' : 'orange'">{{ record.user_type }} {{ record.user_type === 'Mobile' ? record.mode === 0 ? ' (Offline)' : ' (Online)' : '' }}</a-tag>
          </template>
        </a-table>
      </div>
    </div>
    <formModal ref="formModal" :key="componentKey" :table="table" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import formModal from '../form'
import { mapGetters } from 'vuex'

const data = []

export default {
  components: {
    formModal,
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Name',
          dataIndex: 'name',
          width: 300,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        // {
        //   title: 'Username',
        //   dataIndex: 'username',
        //   scopedSlots: {
        //     filterDropdown: 'filterDropdown',
        //     filterIcon: 'filterIcon',
        //     customRender: 'customRender',
        //   },
        //   onFilter: (value, record) =>
        //     record.username
        //       .toString()
        //       .toLowerCase()
        //       .includes(value.toLowerCase()),
        //   onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //       setTimeout(() => {
        //         this.searchInput.focus()
        //       }, 0)
        //     }
        //   },
        // },
        {
          title: 'Email',
          dataIndex: 'email',
          width: 200,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.email
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Role',
          dataIndex: 'role_Title',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.role_Title
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Cabang',
          dataIndex: 'user_branch',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.user_branch
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Kode Kas',
          dataIndex: 'kode_kas',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode_kas
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          width: 300,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.notes
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Allow App',
          key: 'appLists',
          width: 200,
          scopedSlots: { customRender: 'appList' },
        },
        {
          title: 'Default App',
          key: 'id_Default_App',
          width: 200,
          scopedSlots: { customRender: 'id_Default_App' },
        },
        {
          title: 'User Type',
          key: 'user_type',
          width: 200,
          scopedSlots: { customRender: 'user_type' },
        },
        // {
        //   title: 'Mobile Status',
        //   dataIndex: 'mobile_status',
        //   key: 'mobile_status',
        //   // width: 50,
        // },
      ],
      table: 'users',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data,
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(x => x.id === record.id_Default_App)
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    async changePassword(params1) {
      if (this.popinput.password === this.popinput.re_password) {
        var index = this.data.findIndex(x => x.id === params1)
        // console.log('this.data[index]', this.data[index])
        var fd = {
          id: this.data[index].id,
          password: this.popinput.password,
        }
        var res = await lou.customUrlPut('users/changepassword', fd, true, true)
        if (res) {
          this.data[index].changePassPop = false
          lou.shownotif('Success', res.message, 'success')
          this.popinput = {
            password: '',
            re_password: '',
          }
        }
      } else {
        lou.shownotif('Not Valid!', 'Password tidak sama!')
      }
    },
    sendToForm(action, data = {}, id = '') {
      // console.log(data)
      this.$refs.formModal.showModal(action, data, id)
    },
    async getData(onclick = false) {
      this.componentKey += 1
      var res = ''
      res = await lou.readMaster('users', false, true)
      var ndata = []
      if (res.data.length !== 0) {
        res.data.forEach(element => {
          if (this.user.isHidden) {
            ndata.push(element)
          } else {
            if (element.hidden === 0) {
              element.changePassPop = false
            }
          }
        })
      }
      if (onclick) {
        lou.shownotif('Refresh', 'Data telah di refresh.')
      }
      // console.log('res.data', res.data)
      this.data = ndata
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('users/' + deldata.id, true, true)
          this.data = response.data
          this.getData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>
